
.p-editor-container .p-editor-toolbar {
    background: inherit !important;

}
.p-editor-container .p-editor-content .ql-editor {
    background: inherit !important;
}
.mbsc-ios.mbsc-textfield-inner.mbsc-disabled {
    opacity:1 !important;
}
.mbsc-grid *, .mbsc-grid-unresp *, .mbsc-grid-fixed * {
    width:100%
}
.p-button-icon{
    padding-left:1em !important;

}
.p-menu{
    width: 100% !important;
}

.p-button-icon-only {
    height:2em;
    justify-content: left !important;
}
.mbsc-ios.mbsc-input {
    background: transparent !important;
}
.mbsc-ios.mbsc-input input {
    background: transparent !important
}
.mbsc-mobiscroll .mbsc-lv-item {
    padding:0 !important
}
.p-card .p-card-body {
    padding: .25rem !important;
}
.mbsc-ios.mbsc-textfield-wrapper-box, .mbsc-ios.mbsc-textfield-wrapper-outline {
    margin:0 !important;
}
.mbsc-control-w:hover {
    /* add any styles you want to apply on hover */
    background-color: #ddd;
  }

input:disabled{
    font-weight:bold;
}
.signatureCanvas{
    background-color:lightgrey;
    border: 1px solid black;
}
.md-cancelled {
    position: absolute;
    top: 0px;
    width:50em;
    height:15em;
    left: 180px;
    z-index:9999;
    font-size: 5px;
}
.p-checkbox {
    width:100% !important;
    padding:.2em !important;
}

.p-checkbox .p-checkbox-box {
    height:2.5em !important;
    width:2.5em !important;
}
.for-load{
    width:53.5% !important
}
.for-time-label{
    width:64.2% !important
}
.for-freight-label{
    width:55.5% !important
}
.for-freight-time-label{
    width:115% !important
}
.for-load-order-label{
    width:61% !important
}
.react-pdf__Page__canvas{
    width:100% !important;
    max-width: 595px !important;
}
.p-inputgroup-addon{
    flex-basis: 45%;
    flex-shrink: 0; /* Prevent shrinking */
    flex-grow: 0; /* Prevent growing */

}
.weight-material{
    white-space: nowrap;
    width:65% !important;
}
.ql-container {
    height: inherit !important;
}
.p-inputgroup .p-inputtext, .p-fluid .p-inputgroup .p-inputtext, .p-inputgroup .p-inputwrapper, .p-fluid .p-inputgroup .p-input {
    flex: 1 1 auto;
   
}
.mbsc-textfield-inner {
display: initial !important;
}
.mbsc-ios.mbsc-textarea {

    height: inherit !important;
}
textarea:disabled{
    font-weight:bold;
    opacity: 0.8 !important;
}

.p-steps-item {
    background: transparent !important;
}
.p-disabled, .p-component:disabled {
    background:#0000001c;
    opacity:1 !important;
}
input:disabled {
    
    background:#e3e3e3 !important;
    opacity:1 !important;
}

.p-dialog .p-dialog-content {
    padding:0 !important;
}
  .disabledLabel{

    background:#0000001c !important;
    opacity:1 !important;
}
.mbsc-ios.mbsc-label.mbsc-disabled {
    opacity:1 !important;
}
.mbsc-ios.mbsc-input input:disabled{
    opacity:.8 !important;
}

.p-overlaypanel .p-overlaypanel-content {
    padding:0 !important;
}
.mbsc-col-1, .mbsc-col-2, .mbsc-col-3, .mbsc-col-4, .mbsc-col-5, .mbsc-col-6, .mbsc-col-7, .mbsc-col-8, .mbsc-col-9, .mbsc-col-10, .mbsc-col-11, .mbsc-col-12, .mbsc-col, .mbsc-col-auto, .mbsc-col-sm-1, .mbsc-col-sm-2, .mbsc-col-sm-3, .mbsc-col-sm-4, .mbsc-col-sm-5, .mbsc-col-sm-6, .mbsc-col-sm-7, .mbsc-col-sm-8, .mbsc-col-sm-9, .mbsc-col-sm-10, .mbsc-col-sm-11, .mbsc-col-sm-12, .mbsc-col-sm, .mbsc-col-sm-auto, .mbsc-col-md-1, .mbsc-col-md-2, .mbsc-col-md-3, .mbsc-col-md-4, .mbsc-col-md-5, .mbsc-col-md-6, .mbsc-col-md-7, .mbsc-col-md-8, .mbsc-col-md-9, .mbsc-col-md-10, .mbsc-col-md-11, .mbsc-col-md-12, .mbsc-col-md, .mbsc-col-md-auto, .mbsc-col-lg-1, .mbsc-col-lg-2, .mbsc-col-lg-3, .mbsc-col-lg-4, .mbsc-col-lg-5, .mbsc-col-lg-6, .mbsc-col-lg-7, .mbsc-col-lg-8, .mbsc-col-lg-9, .mbsc-col-lg-10, .mbsc-col-lg-11, .mbsc-col-lg-12, .mbsc-col-lg, .mbsc-col-lg-auto, .mbsc-col-xl-1, .mbsc-col-xl-2, .mbsc-col-xl-3, .mbsc-col-xl-4, .mbsc-col-xl-5, .mbsc-col-xl-6, .mbsc-col-xl-7, .mbsc-col-xl-8, .mbsc-col-xl-9, .mbsc-col-xl-10, .mbsc-col-xl-11, .mbsc-col-xl-12, .mbsc-col-xl, .mbsc-col-xl-auto {
padding-left:.1em !important;
padding-right:.1em !important;
}